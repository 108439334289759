import { Module } from "vuex"

export interface AuthState {
    isSignedIn: boolean
    isAuthenticating: boolean
    profile: LoginNewProfile | null
    authClaims: {
        permissions: {
            users: boolean
            allBusses: boolean
        }
    }
}

const module: Module<AuthState, unknown> = {
    state: {
        isSignedIn: false,
        isAuthenticating: true,
        profile: null,
        authClaims: {
            permissions: {
                users: false,
                allBusses: false
            }
        }
    },
    mutations: {
        setAuthClaims(state, claims) {
            state.authClaims.permissions.users = claims.permissions.users == true
            state.authClaims.permissions.allBusses = claims.permissions.allBusses == true
        }
    }
}
export default module