
import { computed, defineComponent, watch } from "vue"
import appNavigation from "./components/app/app-navigation.vue"
import alertDialog from "./components/app/alert-dialog.vue"
import filterDialog from "./components/app/filter-dialog.vue"
import router from "./router"
import store from "./store"

export default defineComponent({
    setup() {
        const isLoading = computed(() => {
            return store.state.auth!.isAuthenticating
        })

        watch(() => store.state.auth!.isSignedIn, (val) => {
            if (val) {
                if (store.state.auth!.authClaims.permissions.users) {
                    loginNew.dropdown.layout("admin").item("user-management").setCallback(() => {
                        router.push("/manage/permissions").catch(() => { return })
                    })
                    loginNew.dropdown.layout("admin").item("user-management").show()
                    loginNew.dropdown.layout("admin").show()
                } else {
                    loginNew.dropdown.layout("admin").item("user-management").hide()
                }
                loginNew.dropdown.layout("filter").show()
                loginNew.dropdown.layout("filter").item("line_filter").setCallback(() => {
                    //store.state.app!.linefilter = "024"
                    store.commit("filter", {
                        title: "Filterung",
                        message: "Definieren Sie die Bus-Linie die angezeigt werden soll. Lassen sie das Feld leer um alle anzuzeigen."
                    })
                })
            } else {
                loginNew.dropdown.layout("admin").hide()
                loginNew.dropdown.layout("filter").hide()
            }
        }, { immediate: true })

        return {
            isLoading
        }
    },
    components: {
        appNavigation,
        alertDialog,
        filterDialog
    }
})
