
import store from "@/store"
import { computed, defineComponent } from "vue"

export default defineComponent({
    setup() {
        const active = computed(() => {
            return store.state.app!.alert.active
        })
        const title = computed(() => {
            return store.state.app!.alert.title
        })
        const text = computed(() => {
            return store.state.app!.alert.message
        })

        const closeDialog = () => {
            store.state.app!.alert.active = false
        }

        return {
            active,
            title,
            text,
            closeDialog
        }
    }
})
