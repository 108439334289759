
import { defineComponent } from "vue"
import logo from "@/assets/logo_colored.svg"
import logoDark from "@/assets/logo_dark.svg"

export default defineComponent({
    setup() {
        const images = {
            logoDark,
            logo
        }

        return {
            images
        }
    }
})
