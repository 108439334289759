import Vue from "vue"
import app from "./app.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import "leaflet/dist/leaflet.css"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "@mdi/font/css/materialdesignicons.css"

import "@/firebase"

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(app)
}).$mount("#app")
