import { Module } from "vuex"

export interface AppState {
    alert: {
        title: string
        message: string
        active: boolean
    },
    filter: {
        title: string
        message: string
        active: boolean
    },
    linefilter: string,
    vehiclefilter: string
}

const module: Module<AppState, unknown> = {
    state: {
        alert: {
            title: "",
            message: "",
            active: false
        },
        filter: {
            title: "",
            message: "",
            active: false
        },
        linefilter: "",
        vehiclefilter: ""
    },
    mutations: {
        alert(state, claims) {
            state.alert.title = claims.title
            state.alert.message = claims.message
            state.alert.active = true
        },
        filter(state, claims) {
            state.filter.title = claims.title
            state.filter.message = claims.message
            state.filter.active = true
        }
    }
}
export default module