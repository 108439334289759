import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/home-view.vue")
    },
    {
        path: "/map",
        name: "map",
        component: () => import(/* webpackChunkName: "map" */ "../views/map-view.vue")
    },
    {
        path: "/loge",
        name: "loge",
        component: () => import(/* webpackChunkName: "map" */ "../views/loge-view.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    },
    {
        path: "/manage/permissions",
        name: "permissions",
        component: () => import(/* webpackChunkName: "permissions" */ "../views/manage/permissions-view.vue")
    },
    {
        path: "/error/missing_permissions",
        name: "missing_permissions",
        component: () => import(/* webpackChunkName: "missing_permissions" */ "../views/error/missing-permissions-view.vue")
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/error/error-view.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
