import Vue from "vue"
import Vuex from "vuex"

import app, { AppState } from "./modules/app"
import auth, { AuthState } from "./modules/auth"

Vue.use(Vuex)

export interface StoreState {
    app?: AppState
    auth?: AuthState
}

export default new Vuex.Store<StoreState>({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        app,
        auth
    }
})
