import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getAuth } from "firebase/auth"
import { FirebaseClient } from "@loginnew/firebase-client"
import store from "./store"
import router from "./router"

const firebaseApp = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APPID
})

const firestore = getFirestore(firebaseApp)
const database = getDatabase(firebaseApp)
const functions = getFunctions(firebaseApp, "europe-west3")
const auth = getAuth(firebaseApp)

if (location.hostname == "localhost") {
    //connectFirestoreEmulator(firestore, "localhost", 8081)
    //connectFunctionsEmulator(functions, "localhost", 5001)
}

const client = new FirebaseClient(process.env.VUE_APP_LOGIN_NEW_CLIENT_ID, {
    functions,
    auth
}, {
    redirectPath: "/login"
})
client.onStartLoading(() => {
    store.state.auth!.isAuthenticating = true
})
client.onStopLoading(() => {
    store.state.auth!.isAuthenticating = false
})
client.onAuthStateChanged(async ({ user, error }) => {
    store.state.auth!.profile = user
    if (error) {
        switch (error) {
            case "missing_permissions":
                router.push("/error/missing_permissions")
                break
            default:
                router.push("/error")
                break
        }
    } else {
        if (client.isSignedIn()) {
            const authClaims = await client.getAuthClaims()
            store.commit("setAuthClaims", authClaims)
        } else {
            // Anmelden erzwingen (Silent-Auth funktioniert dennoch)
            // loginNew.auth()
        }
    }
    store.state.auth!.isSignedIn = client.isSignedIn()
})

loginNew.dropdown.layout("admin").register([
    {
        id: "user-management",
        title: "Berechtigungen",
        icon: "mdi-shield-account",
        type: "button",
        hidden: true
    },
    {
        id: "filter",
        title: "Anzeige filtern",
        icon: "mdi-filter",
        type: "button",
        hidden: true
    }
], {
    position: 99
})

loginNew.dropdown.layout("filter").register([
    {
        id: "line_filter",
        title: "Anzeige filtern",
        icon: "mdi-filter",
        type: "button",
        hidden: false
    }
], {
    position: 99
})

export {
    firestore,
    database,
    functions,
    auth,
    client
}